import ServiceApi from '../ServiceApi';
import ServiceFilter from '../ServicesFilter/ServiceFilter';
import ServiceWidgetPoi from '../ServicesWidgetsMap/ServiceWidgetPoi';
import UtilDate from '../../utils/UtilDate';
import UtilNumbers from '../../utils/UtilNumbers';
import SchemeKitTable from '../../schemes/SchemeKitTable';
import _ from 'lodash';

export default class ServiceMapTable {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} getTable - маршрут для получения таблицы
     */
    static _requestRouts = {
        getTable: '/main/map-table',
        editTableStatus: '/main/edit-value',
        editTable: '/main/edit-value',
        getEditValues: '/main/edit-values'
    };

    static statusMap = {
        'user_status': {
            'stock': 'Запас. Выборка',
            'reserve': 'Резерв из выборки',
            'reserve2': 'Резерв 1',
            'reserve3': 'Резерв 2',
            'free': 'Свободная продажа',
            'remove': 'Удаляем',
            'reset': 'Вернуть агентский',
            'agree': 'Согласовано',
            'dismantling': 'Демонтаж',
        },
        'status': {
            'free': 'Свободная продажа',
            'busy': 'Продано',
            'reserved': 'Резерв из выборки',
            'reserved2': 'Резерв 1',
            'reserved3': 'Резерв 2',
            'booked': 'Забронировано',
            'dismantling': 'Демонтаж',
        }
    };

    static async getMapTable(page = 0, kitItemId = '', tableFilterData = {}, actionAfter = () => {}) {
        const reqBody = this._getMapTableBefore(page, kitItemId, tableFilterData);
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getTable, reqBody);
            const {data = {}} = res;
            const {items: rows = [], count: pageCount = 0, editable: editableData = []} = data;
            await this._getMapTableColumnsScheme((columnsScheme) => {
                const dataAfter = this._getMapTableAfter(rows, columnsScheme, editableData);
                actionAfter(dataAfter.rowsAfter, dataAfter.columnsAfter, parseInt(pageCount));
            });
        } catch (error) {
            console.log(error);
        }
    }

    static async getTemplateItemsColumns(actionAfter = () => {}) {
        await this._getMapTableColumnsScheme((columnsScheme) => {
            const templateItems = this._getTemplateItemsColumnsAfter(columnsScheme);
            actionAfter(templateItems);
        });
    }

    static async setTemplateItemsColumns(columns = [], actionAfter = () => {}) {
        const columnsScheme = this._setTemplateItemsColumnsBefore(columns);
        await this._setMapTableColumnsScheme(columnsScheme, actionAfter);
    }

    static async editMapTableStatus(entityName = '', priceId = '', attribute = '', newValue = '', actionAfter = () => {}) {
        const reqBody = this._editMapTableStatusBefore(entityName, priceId, attribute, newValue);

        try {
            const resBody = await ServiceApi.put(this._microserviceName, this._requestRouts.editTableStatus, reqBody);
            const statusDataAfter = this._editMapTableStatusAfter(attribute, resBody?.data ?? {});
            actionAfter(statusDataAfter);
        } catch (error) {
            console.log(error);
        }
    }

    static _editMapTableStatusBefore(entityName = '', priceId = '', attribute = '', newValue = '') {
        const reqBody = {
            entity: {
                entity_name: String(entityName),
                attribute: String(attribute),
                entity_id: String(priceId),
                value: String(newValue)
            }
        };

        return reqBody;
    }

    static _editMapTableStatusAfter(attribute = '', statusData = {}) {
        const statusDataAfter = {
            id: String(statusData?.id ?? ''),
            value: String(statusData[attribute] ?? ''),
            color: String(statusData[`${attribute}_color`] ?? ''),
            label: String(statusData[`${attribute}_label`] ?? ''),
        };

        return statusDataAfter;
    }

    static async _getMapTableColumnsScheme(actionAfter = () => {}) {
        await ServiceWidgetPoi.postStateWidgets({}, async (data) => {
            const {kitTableColumnsScheme = {}} = data;
            if (Object.keys(kitTableColumnsScheme).length === 0) {
                this._setMapTableColumnsScheme(SchemeKitTable, (columnsScheme) => {
                    const columnsSchemeAfter = this._getMapTableColumnsSchemeAfter(columnsScheme);
                    actionAfter(columnsSchemeAfter);
                });
            }
            else {
                const columnsSchemeAfter = this._getMapTableColumnsSchemeAfter(kitTableColumnsScheme);
                actionAfter(columnsSchemeAfter);
            }
        });
    }

    static _prepareMapTableColumnsScheme(columnsScheme = {}) {
        const columnsSchemePrepared = Object.keys(SchemeKitTable).reduce((columnsSchemePrepared, schemeKey) => {
            const columnsStatic = SchemeKitTable[schemeKey] ?? [];
            const columns = columnsScheme[schemeKey] ?? [...columnsStatic];

            const diffAdd = _.differenceBy(columnsStatic, columns, 'prop').map(diffAddItem => {
                return {
                    prop: diffAddItem.prop ?? '',
                    isShowColumn: diffAddItem.isShowColumn ?? false
                };
            });
            const diffRemove = _.differenceBy(columns, columnsStatic, 'prop').map(diffRemoveItem => String(diffRemoveItem.prop) ?? '');

            _.remove(columns, (column) => diffRemove.includes(String(column.prop) ?? ''));
            const columnsPrepared = [...columns, ...diffAdd];
            columnsSchemePrepared[schemeKey] = columnsPrepared;

            return columnsSchemePrepared;
        }, {});

        return columnsSchemePrepared
    }

    static _getMapTableColumnsSchemeAfter(columnsScheme = {}) {
        const columnsSchemePrepared = this._prepareMapTableColumnsScheme(columnsScheme);
        const columnsSchemeAfter = Object.keys(columnsSchemePrepared).reduce((columnsSchemeAfter, columnsSchemeKey) => {
            const columnsStatic = SchemeKitTable[columnsSchemeKey] ?? [];
            const columns = columnsSchemePrepared[columnsSchemeKey];

            const columnsAfter = columns.map(column => {
                const {prop = '', isShowColumn = false} = column;
                const columnStatic = columnsStatic.find(columnsStatic => {
                    const {prop: propStatic = ''} = columnsStatic;
                    return String(propStatic) === String(prop);
                }) ?? {};

                return {
                    ...columnStatic,
                    isShowColumn: Boolean(isShowColumn)
                };
            });

            columnsSchemeAfter[columnsSchemeKey] = columnsAfter;
            return columnsSchemeAfter;
        }, {});

        return columnsSchemeAfter;
    }

    static async _setMapTableColumnsScheme(columnsScheme = {}, actionAfter = () => {}) {
        const reqBody = this._setMapTableColumnsSchemeBefore(columnsScheme);
        await ServiceWidgetPoi.postStateWidgets(reqBody, () => actionAfter(columnsScheme));
    }

    static _setMapTableColumnsSchemeBefore(columnsScheme = {}) {
        const columnsSchemeToSet = Object.keys(columnsScheme).reduce((columnsSchemeToSet, columnsSchemeKey) => {
            const columns = columnsScheme[columnsSchemeKey];
            const columnsToSet = columns.map(column => {
                const {prop = '', isShowColumn = false} = column;
                const columnToSet = {prop: String(prop), isShowColumn: Boolean(isShowColumn)};
                return columnToSet;
            });

            columnsSchemeToSet[columnsSchemeKey] = columnsToSet;

            return columnsSchemeToSet;
        }, {});

        const reqBody = {mapState: {kitTableColumnsScheme: columnsSchemeToSet}};

        return reqBody;
    }

    static _getTemplateItemsColumnsAfter(columnsScheme = {}) {
        const templateItemsColumnsAfter = Object.keys(columnsScheme).reduce((templateItemsColumns, columnsSchemeKey) => {
            const columns = columnsScheme[columnsSchemeKey];
            const columnsAfter = columns.map(column => {
                const {prop = '', isShowColumn = false, label = ''} = column;
                const templateColumn = {
                    extraData: {...column},
                    schemeKey: columnsSchemeKey,
                    attribute: String(prop),
                    checked: Boolean(isShowColumn),
                    label: String(label)
                };

                return templateColumn;
            });

            templateItemsColumns.push(...columnsAfter);
            return templateItemsColumns;
        }, []);

        return templateItemsColumnsAfter;
    }

    static _setTemplateItemsColumnsBefore(columns = []) {
        const templateItemsColumnsBefore = columns.reduce((columnsScheme, column) => {
            const {extraData = {}, schemeKey = '', checked = false} = column;
            const columnScheme = {
                ...extraData,
                isShowColumn: Boolean(checked)
            };
            const columns = columnsScheme[schemeKey] ?? [];
            columns.push(columnScheme);
            columnsScheme[schemeKey] = columns;

            return columnsScheme;
        }, {});

        return templateItemsColumnsBefore
    }

    static _getMapTableBefore(page = 0, kitItemId = '', tableFilterData = {}) {
        const filterSelected = ServiceFilter.getFilterSelected();
        const {filter = {}, sort = {}} = tableFilterData;
        const reqBody = {
            filter_main: {
                main_params: {...filterSelected},
                price_group_id: String(kitItemId)
            },
            filter_table: {
                page: parseInt(page),
                filter: {...filter},
                sort: {...sort}
            }
        };

        return reqBody;
    }

    static _getMapTableAfter(rows = [], columnsScheme = {}, editableData = []) {
        const dataAfter = {
            columnsAfter: this._getMapTableAfterColumns(rows, columnsScheme, editableData),
            rowsAfter: this._getMapTableAfterRows(rows, columnsScheme, editableData)
        };

        return dataAfter;
    }

    static _getMapTableAfterColumns(rows = [], columnsScheme = {}, editableData = []) {
        const datesTotal = rows.reduce((datesTotal, row) => {
            const {prices = []} = row;

            const datesRow = prices.map(price => {
                const {date = ''} = price;
                return String(date);
            }).filter(date => date !== '');

            const datesAdd = datesRow.filter(date => !datesTotal.includes(date));
            datesTotal.push(...datesAdd);
            datesTotal.sort((dateA, dateB) => {
                return Date.parse(dateA) - Date.parse(dateB);
            });

            return datesTotal;
        }, []);

        const {kitTableColumnsBase = [], kitTableColumnsPrices = [], kitTableColumnsStatus = []} = columnsScheme;

        const columnsPricesAfter = [...kitTableColumnsPrices, ...kitTableColumnsStatus].reduce((columnsPrices, columnPrice) => {
            const columnPricesDates = datesTotal.map(date => {
                const PROPS_STATUS = ['status', 'user_status'];

                const columnProp = String(columnPrice?.prop ?? '');
                const editableDataItem = editableData.find(editableItem => String(editableItem?.attribute ?? '') === columnProp) ?? {};

                const columnPriceDate = {
                    ...columnPrice,
                    prop: `${columnProp}__${date}`,
                    label: `${UtilDate.getMonthsName(date)}. ${columnPrice?.label}`,
                    isCanEditText: String(editableDataItem?.inputType ?? '') === 'text',
                    isCanEditSelect: String(editableDataItem?.inputType ?? '') === 'select' && !PROPS_STATUS.includes(columnProp),
                    entityName: String(editableDataItem?.entity ?? ''),
                };

                return columnPriceDate;
            });

            columnsPrices.push(...columnPricesDates);

            return columnsPrices;
        }, []);

        const columnsAfter = [...kitTableColumnsBase, ...columnsPricesAfter].map(column => {
            const columnProp = String(column?.prop ?? '');
            const editableDataItem = editableData.find(editableItem => String(editableItem?.attribute ?? '') === columnProp) ?? {};

            return { 
                isCanEditText: String(editableDataItem?.inputType ?? '') === 'text',
                isCanEditSelect: String(editableDataItem?.inputType ?? '') === 'select',
                entityName: String(editableDataItem?.entity ?? ''),
                ...column
            };
        }).filter(column => {
            const {isShowColumn = false} = column;
            return Boolean(isShowColumn);
        });

        return columnsAfter;
    }

    static async getMapTableAfterRows(rows = [], editableData = [], actionAfter = () => {}) {
        await this._getMapTableColumnsScheme((columnsScheme) => {
            const rowsAfter = this._getMapTableAfterRows(rows, columnsScheme, editableData);
            actionAfter(rowsAfter);
        });
    }

    static _getMapTableAfterRows(rows = [], columnsScheme = {}, editableData = []) {
        const rowsAfter = rows.map(row => {
            const {
                id = '',
                code = '',
                sideUserInfo: {
                    opn_number = '',
                    supp_item_id = '',
                    realAddress = '',
                    material = '',
                    move_direction = '',
                    print_price = '',
                    install_price = '',
                    additional_install_price = '',
                    ots: ots_agency = '',
                    grp: grp_agency = '',
                    ext_field_1 = '',
                    ext_field_2 = '',
                    ext_field_3 = '',
                    ext_field_4 = '',
                    ext_field_5 = '',
                    ext_field_6 = '',
                    ext_field_7 = '',
                    ext_field_8 = '',
                    ext_field_9 = '',
                    ext_field_10 = '',
                    image_src = ''
                } = {},
                propertyLinks: {
                    supp_id: {value: supplier = ''} = {},
                    type_id: {value: type = ''} = {},
                    kind_id: {value: kind = ''} = {},
                    format_id: {value: format = ''} = {},
                    lighted: {value: light = ''} = {}
                } = {},
                catalogData: {
                    photo = {},
                    ots: ots_system = '',
                    grp: grp_system = '',
                    oohdesk_id = '',
                    espar_id = ''
                },
                marker: {
                    city: {name: cityName = '',} = {},
                    lat = '',
                    lng = '',
                    direction = ''
                } = {},
                prices = []
            } = row;

            const {kitTableColumnsPrices = [], kitTableColumnsStatus = []} = columnsScheme;

            const pricesKeys = kitTableColumnsPrices.map(column => String(column?.prop));
            const statusKeys = kitTableColumnsStatus.map(column => String(column?.prop));

            let totalPrice = 0;
            let totalAgencyPrice = 0;
            let periodCount = prices.length;
            const rowPrices = prices.reduce((rowPrices, price) => {
                const {date = '', id: priceId = ''} = price;
                let discount = Number(price['price']) > 0
                    ? (Number(price['price']) - Number(price['agency_price'])) / Number(price['price'])
                    : 0;
                totalPrice += Number(price['price']);
                totalAgencyPrice += Number(price['agency_price']);
                price['discount'] = discount;
                pricesKeys.forEach(priceKey => {
                    let pricePepared;
                    switch (priceKey) {
                        case 'mount_date_plan':
                        case 'mount_date_fact':
                            if(price[priceKey]){
                                const priceCrutch = price[priceKey] ?? '0000-00-00';
                                let [year = "", month = "", day = ""] = priceCrutch.split("-");
                                pricePepared = UtilDate.toPrettyString(Number(year), Number(month), Number(day));
                            }else{
                                pricePepared = "";
                            }
                            break;
                        case 'layout_names':
                        case 'project_names':
                        case 'video_time':
                        case 'video_frequency':
                        case 'video_block':
                        case 'video_block_clients':
                            pricePepared = price[priceKey];
                            pricePepared = pricePepared === undefined || pricePepared === null ? '' : String(pricePepared)
                            break;
                        case 'discount':
                            pricePepared = UtilNumbers.toDiscount(price[priceKey]);
                            break;
                        default:
                            pricePepared = UtilNumbers.toPrice(price[priceKey]);
                    }
                    rowPrices = {
                        ...rowPrices,
                        [`${priceKey}__${date}`]: pricePepared
                    };
                });

                statusKeys.forEach(statusKey => {
                    const editableDataItem = editableData.find(editableItem => String(editableItem?.attribute) === String(statusKey)) ?? {};
                    const {values: editableItems = []} = editableDataItem;
                    const dropdownItems = editableItems.map(editableItem => {
                        return {
                            id: String(editableItem),
                            value: this.statusMap[statusKey][editableItem] ?? ''
                        };
                    });

                    rowPrices = {
                        ...rowPrices,
                        [`${statusKey}__${date}`]: {
                            value: String(price[statusKey] ?? ''),
                            items: [...dropdownItems],
                            defaultTitle: String(price[`${statusKey}_label`] ?? ''),
                            extraData: {
                                priceId: String(priceId),
                                ...editableDataItem
                            }
                        }
                    };
                });

                return rowPrices;
            }, {});

            const rowAfter = {
                id: String(id),
                oohdesk_id: String(oohdesk_id),
                espar_id: String(espar_id),
                photo,
                opn_number: String(opn_number),
                city: String(cityName),
                supp_item_id: String(supp_item_id),
                realAddress: String(realAddress),
                code: String(code),
                supp_id: String(supplier),
                type_id: String(type),
                kind_id: String(kind),
                format_id: String(format),
                lighted: String(light),
                image_link: String(image_src),
                ots: String(ots_agency),
                grp: String(grp_agency),
                ots_system: String(ots_system),
                grp_system: String(grp_system),
                material: String(material),
                move_direction: String(move_direction),
                print_price: String(print_price),
                install_price: String(install_price),
                additional_install_price: String(additional_install_price),
                ext_field_1: String(ext_field_1),
                ext_field_2: String(ext_field_2),
                ext_field_3: String(ext_field_3),
                ext_field_4: String(ext_field_4),
                ext_field_5: String(ext_field_5),
                ext_field_6: String(ext_field_6),
                ext_field_7: String(ext_field_7),
                ext_field_8: String(ext_field_8),
                ext_field_9: String(ext_field_9),
                ext_field_10: String(ext_field_10),
                lat: String(lat),
                lng: String(lng),
                direction: String(Number(direction) * 360 / 16),
                period_count: String(periodCount),
                total_price: UtilNumbers.toPrice(totalPrice),
                total_agency_price: UtilNumbers.toPrice(totalAgencyPrice),
                extraData: {...row},
                ...rowPrices
            };

            return rowAfter;
        });

        return rowsAfter;
    }

    static async editTable(entityName = '', attribute = '', entityId = '', value = '') {
        return this._editTable(entityName, attribute, entityId, value);
    }

    static async _editTable(entityName = '', attribute = '', entityId = '', value = '') {
        const requestBody = this._editTableBefore(entityName, attribute, entityId, value);

        try {
            await ServiceApi.put(this._microserviceName, this._requestRouts.editTable, requestBody);

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    static _editTableBefore(entityName = '', attribute = '', entityId = '', value = '') {
        const requestBody = {
            entity: {
                entity_name: String(entityName),
                attribute: String(attribute),
                entity_id: parseInt(entityId),
                value: String(value)
            }
        };

        return requestBody;
    }

    static async getEditValues(entityName = '', attribute = '') {
        return this._getEditValues(entityName, attribute);
    }

    static async _getEditValues(entityName = '', attribute = '') {
        const requestBody = this._getEditValuesBefore(entityName, attribute);

        try {
            const responseBody = await ServiceApi.post(this._microserviceName, this._requestRouts.getEditValues, requestBody);
            const editTableValues = responseBody?.data ?? [];

            return editTableValues;
        } catch (error) {
            console.log(error);
        }
    }

    static _getEditValuesBefore(entityName = '', attribute = '') {
        const requestBody = {
            entity: {
                entity_name: String(entityName),
                attribute: String(attribute),
            }
        };

        return requestBody;
    }
}
