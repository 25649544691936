<template>
    <div class="layout-tables-kits">
        <LayoutTableKit
            v-for="(kit, index) in kitItems"
            :key="index"
            :kitItemId="getKitProp(kit, 'id')"
            :kitItemName="getKitProp(kit, 'name')"
            :kitItemColor="getKitProp(kit, 'color')"
            :kitStatusColors="getKitStatusColors(kit)"
            :kitItemFinishedAt="getKitPropFinishedAt(kit, 'finishedAt')"
            ref="layoutTableKit"
            @onCloseTable="onCloseTable"
            @onCheckRowTable="onCheckRowTable"
            @onCheckRowsAllTable="onCheckRowsAllTable"
            @onColumnsSettings="onColumnsSettings"
            @onSelectStatus="onSelectStatus"
            @changeFilterTable="changeFilterTable"
        />

        <BaseModal 
            v-if="isColumnsSettings"
            title="Настройки столбцов" 
            @close="onColumnsSettings"
        >
            <template v-slot:modalBody>
                <FormTemplateEdit
                    :isName="false"
                    :isDescription="false"
                    :items="formTemplateEditItems"
                    type="edit"
                    @onSaveTemplate="onSaveColumnsSettings"
                    @onCancel="onColumnsSettings"
                />
            </template>
        </BaseModal>
    </div>
</template>

<script>
import LayoutTableKit from './LayoutTableKit.vue';
import BaseModal from "../components/Base/BaseModal.vue";
import FormTemplateEdit from '../components/Forms/FormTemplateEdit.vue';
import ServiceMapTable from '../services/ServicesMap/ServiceMapTable';
import SchemeKitPanel from '../schemes/SchemeKitPanel';
const {kitTypesReverse} = SchemeKitPanel;
export default {
    name: 'LayoutTablesKits',
    components: {
        LayoutTableKit,
        BaseModal,
        FormTemplateEdit
    },
    data: () => ({
        isColumnsSettings: false,
        formTemplateEditItems: [],
        kitTypesReverse: {...kitTypesReverse}
    }),
    props: {
        kitItems: {
            type: Array,
            default: () => ([])
        },
        legendStatusColors: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        async onColumnsSettings() {
            if (!this.isColumnsSettings)
                await this.getTemplateItemsColumns();
            else
                this.formTemplateEditItems = [];
            
            this.isColumnsSettings = !this.isColumnsSettings;
        },

        changeFilterTable(kitItemId = '', filter = {}) {
            this.$emit('changeFilterTable', kitItemId, filter);
        },

        getKitProp(kit = {}, prop = '') {
            return kit[prop] ?? '';
        },

        getKitPropFinishedAt(kit = {}) {
            return kit?.finishedAt ?? null;
        },

        getKitStatusColors(kit = {}) {
            const {type = ''} = kit;
            const keyType = this.kitTypesReverse[type] ?? '';
            return this.legendStatusColors[keyType] ?? {};
        },

        toggleTable(kitItemId = '') {
            const index = this.kitItems.findIndex(kitItem => String(kitItem?.id) === String(kitItemId));
            
            this.$refs.layoutTableKit[index].toggleTable();
        },

        updateTables(kitItemsIds = []) {
            this.$refs.layoutTableKit.forEach(layoutTableKit => {
                if(kitItemsIds.includes(String(layoutTableKit?.kitItemId)))
                    layoutTableKit.updateTable();
            });
        },

        onSelectStatus(statusSelectedData = {}, kitItemId = '') {
            this.$emit('onSelectStatus', statusSelectedData, kitItemId);
        },

        onCloseTable(kitItemId = '') {
            this.$emit('onCloseTable', kitItemId);
        },
        
        onCheckRowTable(kitItemId = '', rowId = '', markerId = '') {
            this.$emit('onCheckRowTable', kitItemId, rowId, markerId);
        },

        onCheckRowsAllTable(kitItemId = '', rows = []) {
            this.$emit('onCheckRowsAllTable', kitItemId, rows);
        },

        async getTemplateItemsColumns() {
            await ServiceMapTable.getTemplateItemsColumns((templateItems) => {
                this.formTemplateEditItems = templateItems;
            });
        },

        async setTemplateItemsColumns(columns = []) {
            await ServiceMapTable.setTemplateItemsColumns(columns, () => {
                this.$refs.layoutTableKit.forEach(layoutTableKit => layoutTableKit?.updateTable());
            });
        },

        async onSaveColumnsSettings(data) {
            this.isColumnsSettings = false;
            const {data: columns = []} = data;
            
            await this.setTemplateItemsColumns(columns);
        },

        closeFoldersOthers(kitItemsIdsForTables = []) {
            this.$refs.layoutTableKit.forEach(layoutTableKit => {
                if (!kitItemsIdsForTables.includes(String(layoutTableKit?.kitItemId)))
                    layoutTableKit?.closeFolder(layoutTableKit?.kitItemId);
            });
        }
    }
}
</script>